<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item">
          <el-form ref="form" label-width="80px">
            <el-form-item label="" style="margin-left: 1vw">
              <el-input v-model="searchName" placeholder="请输入设备编号"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button>
        <el-button class="upDate" type="success" size="small" icon="el-icon-refresh" @click="update"></el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%; margin-top: 1vh;">
      <el-table-column prop="deviceNumber" label="设备编号" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="sendType" label="推送类型" show-overflow-tooltip align="center">
        <template slot-scope="scope">
          <p v-if="scope.row.sendType == 0">手机</p>
          <p v-if="scope.row.sendType == 1">邮箱</p>
          <p v-if="scope.row.sendType == 2">手机+邮箱</p>
        </template>
      </el-table-column>
      <el-table-column prop="phone" label="推送手机" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="email" label="推送邮箱" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="sendContent" label="推送内容" show-overflow-tooltip align="center"></el-table-column>
<!--      <el-table-column label="操作" align="center" width="300">-->
<!--        <template slot-scope="scope">-->
<!--          <el-button @click="del(scope.row)" type="success" size="small">删除</el-button>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </el-table>
    <div class="block pagingBox">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      value1: "",
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: '',
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    // 重置
    update() {
      this.searchName="";
      this.getList();

    },
    //请求列表数据
    getList() {
      this.$get("/factory/getInsectWarningRecord", {
        page: this.page,
        size: this.size,
        deviceNumber: this.searchName,
        type:0
      }).then((res) => {
        if (res.data.state == "success") {
          this.tableData = res.data.data.datas;
          this.total = res.data.data.count;
          //console.log("获取列表", this.tableData)
        }
      });
    },
    //删除
    // del(item) {
    //   this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then(() => {
    //     this.$get("/remoteSelfHelp/deleteKnowledgeWikipedia", {
    //       id: item.id,
    //     }).then((res) => {
    //       if (res.data.state == "success") {
    //         this.$message.success("删除成功");
    //         this.getList();
    //       } else {
    //         this.$message.info("删除失败");
    //       }
    //     });
    //   }).catch(() => {
    //     this.$message({
    //       type: "info",
    //       message: "已取消删除",
    //     });
    //   });
    // },

// 搜索
    search() {
      // this.page = 1
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    },
  }
}

</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: right;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}

.title /deep/ .el-form-item__content, .el-date-editor, .el-range-editor, .el-input__inner, .el-date-editor--datetimerange {
  padding: 0px 10px !important;
}

.title .upDate {
  position: absolute !important;
  right: 1vw !important;
}

.textA /deep/ .el-input__inner {
  height: 10vh;
}

.drawers /deep/ .el-drawer__header {
  margin-bottom: 4px !important;
}

.msg {
  padding: 1vh 2vw;
  font-size: 1vh;
  width: 88%;
}

.pickerBox {
  width: 90%;
  margin-left: 2vw;
}

.pickerBox /deep/ .el-input__inner {
  width: 100% !important;
  height: 30px !important;
}

.pickerBox /deep/ .el-range-separator, .pickerBox /deep/ .el-input__icon .el-range__icon .el-icon-time {
  line-height: 30px !important;
}

.msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}


</style>

